import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { Children } from '../utils';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'style'> & {
  children?: Children;
  type?: 'reset' | 'submit';
  style?: 'primary' | 'secondary' | 'delete' | 'custom';
  loading?: boolean;
  overrideBaseClassnames?: boolean;
};

export const Button = (props: ButtonProps) => {
  const { children, type, className, style = 'primary', loading, disabled, overrideBaseClassnames, ...rest } = props;

  const baseClassNames = `${className} text-base font-header h-12 shadow rounded-[24px] flex items-center justify-center px-4 min-w-fit w-full`;

  const primaryStyleClassNames =
    'bg-light border-2 border-primary dark:border-none dark:bg-primary text-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:active:bg-dark-lighter active:bg-primary active:border-primary active:text-dark-dark disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-neutral-darkest dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest';

  const secondaryStyleClassNames =
    'bg-color text-primary border-2 border-blue hover:opacity-80 disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-none dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest dark:disabled:border-none';

  const deleteStyleClassNames = 'bg-color text-secondary-dark border-2 border-secondary-dark';

  const customStyleClassNames =
    'bg-blue text-dark border-primary-lighter dark:bg-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest h-12 w-full rounded-[24px] border-2 px-4 font-bold dark:border-none';

  return (
    <button
      {...rest}
      type={type || 'button'}
      className={`${overrideBaseClassnames ? className : baseClassNames} ${
        style === 'primary'
          ? primaryStyleClassNames
          : style === 'secondary'
            ? secondaryStyleClassNames
            : style === 'delete'
              ? deleteStyleClassNames
              : customStyleClassNames
      }`}
      disabled={loading || disabled}
    >
      {loading ? <BarLoader color="#2a9c8e" /> : <p>{children}</p>}
    </button>
  );
};

Button.displayName = 'Button';

export const IconButton = ({
  children,
  id,
  style = 'primary',
  onClick,
  disabled,
  className = '',
}: {
  children: JSX.Element;
  id?: string;
  style?: 'primary' | 'secondary' | 'delete';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const baseClassNames =
    'rounded-full shadow border-2 h-10 w-10 flex justify-center items-center cursor-pointer hover:opacity-80';
  const primaryClassNames = 'border-primary bg-primary text-light';
  const secondaryClassNames = 'border-primary bg-transparent text-primary';
  const deleteStyleClassNames = 'bg-transparent text-error border-error';

  if (disabled) {
    return <div className="rounded-full">{children}</div>;
  }

  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      className={`${baseClassNames} ${
        style === 'primary' ? primaryClassNames : style === 'secondary' ? secondaryClassNames : deleteStyleClassNames
      } ${className}`}
    >
      {children}
    </button>
  );
};

// This will be as our button moving forward.
type NewButtonProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'style'> & {
  id: string;
  icon?: JSX.Element;
  text?: string;
  style?: 'primary' | 'secondary';
  loading?: boolean;
  onClick?: () => void;
};

export const NewButton = (props: NewButtonProps) => {
  const { id, text, icon, style = 'primary', loading, onClick } = props;

  const baseClassNames =
    'text-color dark:border-primary border-blue flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 px-3 font-bold';

  const primaryStyleClassNames =
    'bg-blue dark:bg-primary drop-shadow-[0px_4px_0px_#9CCEC8] dark:drop-shadow-[0px_4px_0px_#195e55]';

  const secondaryStyleClassNames = 'border-blue hover:bg-blue-lighter dark:hover:bg-primary-darker dark:border-primary';

  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      className={`${baseClassNames} ${style === 'primary' ? primaryStyleClassNames : secondaryStyleClassNames}`}
    >
      {loading ? (
        <BarLoader color="#2a9c8e" />
      ) : (
        <div className={`flex flex-row gap-2 ${icon && text ? 'ml-2' : ''}`}>
          {icon}
          <p>{text}</p>
        </div>
      )}
    </button>
  );
};

interface SidebarButtonProps {
  id: string;
  icon: JSX.Element;
  text: string;
  href?: string;
  active?: string;
  setActive?: React.Dispatch<React.SetStateAction<string>>;
  onClick?: () => void;
}

export const SidebarButton: React.FC<SidebarButtonProps> = ({ id, icon, text, href, active, setActive, onClick }) => {
  const navigate = useNavigate();
  const baseClassNames = 'text-color flex font-bold flex-row items-center h-12 rounded-xl px-3 cursor-pointer border-2';
  const activeClassNames =
    active === id
      ? 'bg-blue dark:bg-primary border-none'
      : 'border-blue hover:bg-blue-lighter dark:hover:bg-primary-darker dark:border-primary';

  const handleClick = () => {
    setActive?.(id);
    if (href) {
      navigate(href);
    }
    onClick?.();
  };

  return (
    <button id={`sidebar-${id}`} className={`${baseClassNames} ${activeClassNames}`} onClick={handleClick}>
      {icon}
      <p id={active === id ? 'sidebar-active' : undefined} className="ml-4 hidden group-hover:block lg:block">
        {text}
      </p>
    </button>
  );
};
